import React from 'react';
import './App.css';

const ComingSoonPage = () => {
  return (
    <div className="min-h-screen bg-gradient flex-container">
      <div className="content-container">
        {/* Logo Area */}
        <div className="logo">
          <h1>SearchFur</h1>
        </div>

        {/* Main Content */}
        <div className="main-content">
          <div className="icon-container">
            <div className="timer-icon">⏳</div>
          </div>
          
          <h2 className="main-title">
            We're Building Something New!
          </h2>
          
          <p className="description">
            SearchFur is currently undergoing a major upgrade to bring you an enhanced and more powerful search experience.
          </p>
          
          <div className="content-sections">
            {/* Version Update Notice */}
            <div className="update-notice">
              <h3>Important Update Notice</h3>
              <p>
                We're transitioning to a new version of SearchFur. During this upgrade period:
              </p>
              <ul>
                <li>The previous version of SearchFur is no longer available</li>
                <li>All existing services will be temporarily unavailable</li>
                <li>Your patience during this transition is greatly appreciated</li>
              </ul>
            </div>

            {/* Open Source Announcement */}
            <div className="opensource-section">
              <div className="icon-container">
                <span style={{ fontSize: '2rem' }}>📦</span>
              </div>
              <h3>Legacy Code Going Open Source</h3>
              <p>
                As part of our commitment to the developer community, we're making the previous version of SearchFur open source. The complete codebase will be available on GitHub soon.
              </p>
            </div>

            {/* Archive Notice */}
            <div className="archive-notice">
              <span style={{ fontSize: '1.5rem' }}>📁</span>
              <p>
                Note: The previous version of SearchFur has been archived and is no longer operational. Please wait for our new version launch.
              </p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="footer">
          <p>Thank you for your patience and continued support!</p>
          <p>Stay tuned for our exciting new release</p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;